@import '../../../styles/mixins.scss';

.sectionTitleMenu {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin: 10px 0 30px;

  @include max-screen-455 {
    margin: -3px 0 21px;
  }
  &_mainPage {
    margin: 0 0 28px 0;
    @include max-screen-455 {
      margin: 0 0 28px 0;
    }
  }
  > div:first-child {
    width: calc(100% - 72px);
    @include max-screen-530 {
      width: calc(100% - 60px);
    }
  }
  > div:last-child {
    position: relative;
    top: -px;
    @include max-screen-980 {
      position: relative;
      top: -1px;
    }
  }
  &_sort {
    align-items: center;
    @include max-screen-980 {
      align-items: flex-end;
    }
    > div:first-child {
      width: calc(100% - 82px);
      @include max-screen-530 {
        width: calc(100% - 62px);
      }
    }
  }
  &::first-letter {
    text-transform: uppercase;
  }
}
