@import '../../../../styles/mixins.scss';

.podcastsBlock {
  margin: 0 -11px;
  display: flex;
  flex-wrap: wrap;
  &__item {
    width: 170px;
    height: auto;
    margin: 0 11px 22px;
    @include max-screen-530 {
      width: 157px;
    }
    @include max-screen-375 {
      width: calc(50% - 22px);
    }
    &_mob {
      @include max-screen-530 {
        display: none;
      }
    }
  }
  &__imgWrap {
    position: relative;
    height: 169px;
    margin-bottom: 13px;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    align-items: center;
    border: 1px solid #efeeee;
    background-color: #eeeeee;
    @include max-screen-530 {
      height: 157px;
    }
    @include max-screen-375 {
      width: 100%;
      height: auto;
    }
    &:before {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 70px;
      height: 88px;
      background: url('/icons/book-no-cover.svg') no-repeat 50%;
      background-size: cover;
      z-index: 0;
    }
  }
  &__imgLink {
    position: relative;
    width: 100%;
    height: inherit;
    display: block;
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 0.85;
    }
  }
  &__img {
    width: 100%;
    height: auto;
    color: transparent;
    display: block;
  }
  &__name {
    display: block;
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 6px;
    letter-spacing: -0.2px;
    transition: color 0.3s ease;
    @include max-screen-530 {
      font-size: 14px;
      margin-bottom: 4px;
    }
    &:hover {
      color: #f19001;
    }
  }
  &__author {
    font-size: 14px;
    color: #575757;
    margin-bottom: 6px;
    letter-spacing: -0.2px;
    @include max-screen-530 {
      margin-bottom: 4px;
    }
  }
  &__rating {
    white-space: nowrap;
  }
  &__star {
    display: inline-block;
    width: 13px;
    height: 12px;
    margin: 0 1px 0 1px;
    background-image: url('/icons/card-star-grey.svg');
    background-repeat: no-repeat;
    background-size: cover;
    &_active {
      background-image: url('/icons/card-star-orange.svg');
    }
  }
  &__starRate {
    position: relative;
    font-size: 13px;
    top: -1.4px;
    margin-left: 3px;
  }
  &__type {
    display: block;
    position: absolute;
    right: 10px;
    bottom: 10px;
    padding: 4px 8px 5px;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 10px;
    letter-spacing: 0.2px;
    line-height: 12px;
    background: linear-gradient(180deg, #f7e8b1, #f8df61 30%, #f1a320);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }
}
