.coverSticker {
  position: absolute;
  left: 0;
  top: 10px;
  padding: 1px 4px;
  font-size: 13px;
  z-index: 1;
  white-space: nowrap;
  &_draft {
    background-color: #f09101;
    color: white;
  }
}
