@import '../../../styles/mixins.scss';

.cardsControls {
  &__buttons {
    display: flex;
  }
  &__button {
    width: 24px;
    height: 24px;
    margin-right: 14px;
    cursor: pointer;
    position: relative;
    @include max-screen-530 {
      width: 18px;
      height: 18px;
      margin-right: 18px;
    }
    &:last-child {
      margin-right: 0;
    }
    &:after {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.5;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: cover;
      transition: opacity 0.3s ease;
      background-image: url('/icons/cards-row-icon.svg');
      @include max-screen-530 {
        width: 18px;
        height: 18px;
      }
    }
    &:hover {
      &:after {
        opacity: 0.7;
      }
    }
    &_tiles {
      &:after {
        background-image: url('/icons/cards-tiles-icon.svg');
      }
    }
    &_active {
      &:after {
        opacity: 1;
      }
      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}
