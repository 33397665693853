@import '../../../styles/mixins.scss';

/* Карточка книги */
.bookCard {
  box-sizing: border-box;
  padding: 24px;
  border: 1px solid #d0d0d0;
  margin-bottom: 45px;
  @include max-screen-768 {
    padding: 0;
    border: none;
    margin-bottom: 90px;
  }
  @include max-screen-530 {
    padding: 25px 0;
    border-bottom: 1px solid #cfcfcf;
    margin: 0;
  }

  &:first-child {
    @include max-screen-530 {
      padding-top: 0;
    }
  }
  &:last-child {
    @include max-screen-530 {
      margin-bottom: 25px;
    }
  }

  &:after {
    content: ' ';
    display: table;
    clear: both;
  }
  &__parts {
    display: flex;
  }
  &__left {
    box-sizing: border-box;
    margin-right: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include max-screen-768 {
      margin-right: 22px;
    }
    @include max-screen-530 {
      margin: 0 15px 8px 0;
    }
  }
  &__right {
    width: 100%;
  }
  &__imgWrap {
    box-sizing: border-box;
    position: relative;
    width: 200px;
    height: auto;
    margin-bottom: 10px;
    min-height: 70px;
    @include max-screen-768 {
      width: 185px;
    }
    @include max-screen-530 {
      width: 92px;
      max-height: 135px;
      margin-bottom: 4px;
    }

    a {
      width: 100%;
      height: 100%;
      position: relative;
      display: block;
      z-index: 1;
      overflow: hidden;
      background-color: #eee;
      &:before {
        content: ' ';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 80px;
        height: 100px;
        background: url('/icons/book-no-cover.svg') no-repeat 50%;
        background-size: cover;
        z-index: 0;
        @include max-screen-530 {
          top: 47%;
          width: 40px;
          height: 50px;
        }
      }
      &:hover {
        color: black;
      }
    }
  }
  &__img {
    width: 100%;
    position: relative;
    height: auto;
    display: block;
    color: transparent;
  }
  &__btn {
    @include max-screen-530 {
      display: none;
    }
    &_mob {
      display: none;
      @include max-screen-530 {
        display: block;
      }
    }
  }

  &__type {
    position: absolute;
    right: 8px;
    bottom: 10px;
    padding: 6px 18px;
    background: linear-gradient(180deg, #f7e8b1 0%, #f8df61 30%, #f1a320 100%);
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 14px;
    @include max-screen-530 {
      font-size: 12px;
      padding: 2px 10px 3px;
      right: 4px;
      bottom: 4px;
    }
  }
  &__rating {
    white-space: nowrap;
    margin-bottom: 15px;
    @include max-screen-530 {
      margin-bottom: 0;
    }

    &_tiles {
      display: none;
    }
  }
  &__star {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin: 0 2px;
    background-image: url('/icons/card-star-grey.svg');
    background-repeat: no-repeat;
    background-size: cover;
    @include max-screen-530 {
      width: 13px;
      height: 12px;
      margin: 0 1px;
      margin-bottom: 0;
    }
    &_active {
      background-image: url('/icons/card-star-orange.svg');
    }
  }
  &__starRate {
    position: relative;
    font-size: 13px;
    top: -3px;
    margin-left: 3px;
    @include max-screen-530 {
      top: -1.4px;
    }
  }
  &__img {
    width: 100%;
    height: auto;
    display: block;
    background-color: #f4f4f4;
    color: transparent;
  }
  &__readOnlineBtn {
    position: relative;
    display: inline-block;
    font-size: 19px;
    border-radius: 18px;
    font-weight: bold;
    padding: 16px 20px;
    line-height: 1;
    color: #fff;
    background: linear-gradient(to bottom, #ffb44a, #ff744a);
    box-shadow: 0 4px 0 #de5e00;
    z-index: 1;
    overflow: hidden;
    transition: color 0.3s ease, box-shadow 0.3s ease;
    @include max-screen-768 {
      font-size: 17px;
      padding: 16px 18px;
    }
    @include max-screen-530 {
      padding: 10px 18px;
    }
    &:before {
      content: '';
      display: block;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      transition: opacity 0.3s ease;
      z-index: -1;
    }
    &:hover {
      box-shadow: 0 3px 0 #000;
      text-decoration: none;
      &::before {
        opacity: 1;
      }
    }
  }
  &__info {
    font-size: 14px;
    color: #575757;

    @include max-screen-530 {
      display: flex;
      flex-direction: column;
    }
  }
  &__dateWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @include max-screen-530 {
      margin-bottom: 11px;
      order: 1;
    }
  }
  &__date {
    padding-right: 5px;
    @include max-screen-530 {
      padding-right: 0;
    }

    span {
      white-space: nowrap;
    }
  }
  &__title {
    margin-bottom: 18px;
    font-weight: bold;
    font-size: 22px;
    line-height: 1.4;
    color: #000;
    transition: color 0.3s ease;
    @include max-screen-530 {
      font-size: 14px;
      margin: -4px 0 11px;
    }
    &:hover {
      color: #f09101;
    }
  }
  &__author {
    a {
      color: #575757;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    &_authors {
      a {
        display: inline-block;
        padding: 0 7px 2px 0;
      }
    }
  }
  &__genres {
    color: #575757;
    a {
      color: #575757;
      display: inline-block;
      padding: 0 7px 2px 0;
      text-decoration: underline;
      &:first-letter {
        text-transform: uppercase;
      }
      &:hover {
        text-decoration: none;
      }
    }
    span {
      color: #575757;
      display: inline-block;
      padding: 0 7px 2px 0;
    }
  }
  &__year,
  &__lang,
  &__author {
    margin-bottom: 18px;
    @include max-screen-530 {
      margin-bottom: 12px;
    }
  }
  &__year span,
  &__lang span {
    color: black;
  }
  &__desc {
    font-size: 14px;
    line-height: 21px;

    @include max-screen-530 {
      display: none;
    }

    &_mob {
      display: none;
      @include max-screen-530 {
        margin-bottom: 18px;
        display: block;
      }
    }
  }
  &__annotation {
    margin-bottom: 18px;

    @include max-screen-530 {
      margin-bottom: 15px;
    }
  }
  &__download {
    margin-top: 15px;
    font-size: 13px;
    span {
      white-space: nowrap;
      color: #b93a43;
    }
  }
}

//Второй тип карточки
div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard {
  width: 239px;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0 10px;
  border: none;
  position: relative;
  z-index: 0;
  margin-bottom: 20px;
  @include max-screen-530 {
    width: 50%;
  }
  @include max-screen-320 {
    width: 100%;
  }
  &:before {
    content: '';
    width: calc(100% - 20px);
    height: 100%;
    border: 1px solid #d0d0d0;
    border-radius: 3px;
    display: block;
    position: absolute;
    top: 0;
    left: 9px;
    z-index: -1;
    @include max-screen-530 {
      display: none;
    }
  }

  &__parts {
    flex-direction: column;
  }

  &__left {
    margin-right: 0;
  }

  &__right {
    text-align: center;
  }

  &__imgWrap {
    margin: 6px 0;
    max-width: 208px;
    width: 100%;
    height: 240px;
    border-radius: 2px 2px 0 0;
    overflow: hidden;
    background: #f2f2f2;
    @include max-screen-530 {
      max-width: none;
      height: 190px;
      margin: 0 0 6px 0;
      border-radius: 0;
    }
  }

  &__imgWrap_audio a {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
    padding: 0 4px;
    @include max-screen-530 {
      margin-bottom: 10px;
    }
  }

  &__author {
    margin-bottom: 9px;
    padding: 0 4px;
    @include max-screen-530 {
      margin-bottom: 5px;
    }
    a {
      padding: 0;
      font-size: 13px;
      color-scheme: #575757;
    }
  }

  &__type {
    right: 5px;
    bottom: 5px;
    padding: 3px 6px 4px;
    font-size: 13px;
    text-transform: lowercase;
    border-radius: 3px;
    letter-spacing: 0.2px;
    line-height: 12px;
  }

  &__rating {
    margin-bottom: 8px;
    @include max-screen-530 {
      margin-bottom: 3px;
    }
  }

  &__star {
    width: 14px;
    height: 14px;
    margin: 0 1.4px;
  }

  &__starRate {
    top: -2.5px;
  }

  &__readOnlineBtn {
    margin-bottom: 10px;
    font-size: 14px;
    padding: 8px 10px;
    border-radius: 8px;
    background: linear-gradient(180deg, #fec068 0%, #ffaf54 30%, #ff744a 100%);
    box-shadow: 0 3px 0 #de5e00;
    margin-bottom: 10px;
    @include max-screen-530 {
      width: 100%;
      box-shadow: 0 2px 0 #de5e00;
      border-radius: 3px;
      margin-bottom: 0;
    }
    &:hover {
      box-shadow: 0 3px 0 #000;
      @include max-screen-530 {
        box-shadow: 0 2px 0 #000;
      }
    }
  }

  &__rating,
  &__btn,
  &__dateWrap,
  &__year,
  &__lang,
  &__desc,
  &__download {
    display: none;
  }

  &__btn_mob,
  &__rating_tiles {
    display: block;
    text-align: center;
  }
  &__btn_mob {
    margin-top: auto;
  }
}
