@import '../../../styles/mixins.scss';

.versionSticker {
  position: absolute;
  left: 0;
  top: -17px;
  padding: 2px 4px;
  font-size: 11px;
  color: #fff;
  background: #f3a44b;
  border-radius: 3px 3px 0 0;
  @include max-screen-530 {
    top: -14px;
    padding: 1px 2px;
    font-size: 10px;
  }

  &:before {
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: -4px;
    content: '';
    width: 100%;
    height: 4px;
    background: #f3a44b;
  }
}

div[data-btn-cards='tiles'] ~ div[data-cards='list'] .versionSticker {
  top: 0;
  border-radius: 0;
}
